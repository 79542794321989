import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import AppointmentUseCases from '../../domain/usecases/AppointmentUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value;
      }
  }));
  return formData;
}

export default function AppointmentModal({show, setShow, appointment}) {
  
  const classes = useStyles();

  const [selectedDate, handleDateChange] = useState(appointment ? appointment.appointmentTimestamp : new Date());

  useEffect(()=>{
    if (appointment){
      handleDateChange(appointment.appointmentTimestamp);
    }else{
      handleDateChange(new Date());
    }
  }, [appointment]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (appointment){
      data.id = appointment.id;
    }
    console.log(data);
    const appointmentUseCases = new AppointmentUseCases();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    await appointmentUseCases.save(data, loggedUser.id); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="appointmentForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Citas</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos de la cita<br/>
            </DialogContentText>
            <TextField
              className={classes.formControl}
              autoFocus
              margin="dense"
              name="identityDocumentNumber"
              label="DNI"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.identityDocumentNumber : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="firstName"
              label="Nombres"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.firstName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="lastName"
              label="Apellido Paterno"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.lastName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="motherMaidenName"
              label="Apellido Materno"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.motherMaidenName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="cellphone"
              label="Celular"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.cellphone : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="email"
              label="Email"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.email : ""}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="id_location_area-label">Zona</InputLabel>
              <Select name="id_location_area" defaultValue={appointment ? appointment.id_location_area : 1} required>
                <MenuItem value={1}>Trujillo</MenuItem>
                <MenuItem value={2}>El Porvenir</MenuItem>
                <MenuItem value={3}>Florencia de Mora</MenuItem>
                <MenuItem value={4}>Huanchaco</MenuItem>
                <MenuItem value={5}>La Esperanza</MenuItem>
                <MenuItem value={6}>Laredo</MenuItem>
                <MenuItem value={7}>Moche</MenuItem>
                <MenuItem value={8}>Salaverry</MenuItem>
                <MenuItem value={9}>Simbal</MenuItem>
                <MenuItem value={10}>Victor Larco Herrera</MenuItem>
                <MenuItem value={11}>El Milagro</MenuItem>
                <MenuItem value={12}>Alto Trujillo</MenuItem>
                <MenuItem value={13}>Otros - Este</MenuItem>
                <MenuItem value={14}>Otros - Norte</MenuItem>
                <MenuItem value={15}>Otros - Sur</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="experienceYears"
              label="Años de Experiencia"
              type="text"
              required
              fullWidth
              defaultValue={appointment ? appointment.experienceYears : ""}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="id_position-label">Puesto</InputLabel>
              <Select name="id_position" defaultValue={appointment ? appointment.id_position : 1} required>
                <MenuItem value={1}>Nana</MenuItem>
                <MenuItem value={2}>Nana con apoyo en casa</MenuItem>
                <MenuItem value={3}>Nana - TH</MenuItem>
                <MenuItem value={4}>Trabajadora del Hogar</MenuItem>
                <MenuItem value={5}>Niñera</MenuItem>
                <MenuItem value={6}>Niñera con apoyo en casa</MenuItem>
                <MenuItem value={7}>Niñera - TH</MenuItem>
                <MenuItem value={8}>Limpieza</MenuItem>
                <MenuItem value={9}>Enfermera Geriátrica</MenuItem>
                <MenuItem value={10}>Enfermera Pediátrica</MenuItem>
                <MenuItem value={11}>Cuidadora de Ancianos</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_modality-label">Modalidad</InputLabel>
              <Select name="id_modality" defaultValue={appointment ? appointment.id_modality : 1} required>
                <MenuItem value={1}>Interna (Cama adentro)</MenuItem>
                <MenuItem value={2}>Externa (Cama afuera)</MenuItem>
                <MenuItem value={3}>Part time (Medio tiempo)</MenuItem>
                <MenuItem value={4}>Por días</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="appointmentTimestamp"
                label="Fecha y hora de cita"
                value={selectedDate}
                onChange={handleDateChange}
                onError={console.log}
                format="yyyy-MM-dd HH:mm:ss"
              />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
              <InputLabel id="appointment_type-label">Tipo de Cita</InputLabel>
              <Select name="id_appointment_type" defaultValue={appointment ? appointment.id_appointment_type : 1} required>
                <MenuItem value={1}>Programada</MenuItem>
                <MenuItem value={2}>No Programada</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="outcome-label">Resultado</InputLabel>
              <Select name="outcome" defaultValue={appointment ? appointment.outcome : "Pendiente"} required>
                <MenuItem value="Pendiente">Pendiente</MenuItem>
                <MenuItem value="Aprobada">Aprobada</MenuItem>
                <MenuItem value="Desaprobada">Desaprobada</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}