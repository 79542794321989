import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';
import VideoUseCases from '../../domain/usecases/VideoUseCases';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDateTimePicker} from '@material-ui/pickers';
import FileUpload from '../components/FileUpload';
import CircularProgressModal from '../components/CircularProgressModal';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  if (date.getFullYear && date.getMonth && date.getDate){
    return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
  }else{
    return date;
  }
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

export default function VideoModal({show, setShow, video}) {
  const classes = useStyles();
  const [progressValue, setProgressValue] = useState(0);
  const [progressDialogOpen, setProgressDialogOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [images, setImages] = useState([]);
  const [imagesUpload, setImagesUpload] = useState([]);
  const [directorImages, setDirectorImages] = useState([]);
  const [directorImagesUpload, setDirectorImagesUpload] = useState([]);
  const [movie, setMovie] = useState(null);
  const [movieUpload, setMovieUpload] = useState(null);
  const [trailer, setTrailer] = useState(null);
  const [trailerUpload, setTrailerUpload] = useState(null);

  const deleteImage = (image)=>{
    setImages(images.filter(item=>item !== image));
  }

  const deleteDirectorImage = (image)=>{
    setDirectorImages(directorImages.filter(item=>item !== image));
  }

  const deleteMovie = (movie)=>{
    setMovie(null);
  }

  const deleteTrailer = ()=>{
    setTrailer(null);
  }

  useEffect(()=>{
    //loadServiceOrders();
    setProgressValue(0);
    if (video){
      setSelectedDate(new Date(video.start));
      setImages(video.images ? video.images.split(',') : []);
      setMovie(video.movie);
      setTrailer(video.trailer);
      setDirectorImages(video.directorImages ? video.directorImages.split(',') : []);
    }else{
      setSelectedDate(new Date());
      setImages([]);
      setDirectorImages([]);
      setMovie(null);
      setTrailer(null);
    }
    setImagesUpload([]);
    setDirectorImagesUpload([]);
  }, [video]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (video){
      data._id = video._id;
    }
    data.start = selectedDate.getTime();
    data.videoStatus = parseInt(data.videoStatus, 10);
    if (movieUpload && movieUpload instanceof File){
      data.movieUpload = movieUpload;
    }else{
      data.movie = movie;
    }
    if (trailerUpload && trailerUpload instanceof File){
      data.trailerUpload = trailerUpload;
    }else{
      data.trailer = trailer;
    }
    if (imagesUpload && imagesUpload instanceof Array && imagesUpload.length > 0){
      data.imagesUpload = imagesUpload;
    }else{
      data.images = images;
    }
    if (directorImagesUpload && directorImagesUpload instanceof Array && directorImagesUpload.length > 0){
      data.directorImagesUpload = directorImagesUpload;
    }else{
      data.directorImages = directorImages;
    }
    const videoUseCases = new VideoUseCases();
    setProgressDialogOpen(true);
    setShow(null);
    await videoUseCases.save(data, setProgressValue); 
    setProgressDialogOpen(false);
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Video</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos del video<br/>
            </DialogContentText>
            {/*<FormControl className={classes.formControl}>
              <InputLabel id="id_service_order-label">Órden de Servicio</InputLabel>
              <Select 
                name="id_service_order" 
                defaultValue={video ? video.id_service_order : ""}
                required>
                {serviceOrders.map(serviceOrder=>(
                  <MenuItem key={serviceOrder.id} value={serviceOrder.id}>{serviceOrder.order.client.firstName} {serviceOrder.order.client.lastName} ({getDateString(serviceOrder.created_at)})</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_worker-label">Trabajador</InputLabel>
              <Select 
                name="id_worker" 
                defaultValue={interview ? interview.id_worker : ""}
                required>
                {workers.map(worker=>(
                  <MenuItem key={worker.id} value={worker.id}>{worker.firstName} {worker.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_attending_user-label">Empleado</InputLabel>
              <Select 
                name="id_attending_user" 
                defaultValue={interview ? interview.id_attending_user : currentUserId}
                required>
                {users.map(user=>(
                  <MenuItem key={user.id} value={user.id}>{user.firstName} {user.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            */}
            <TextField
              className={classes.formControl}
              margin="dense"
              name="title"
              label="Título"
              type="text"
              fullWidth
              required
              defaultValue={video ? video.title : ""}
            />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="start"
                label="Fecha y hora del estreno"
                value={selectedDate}
                onChange={setSelectedDate}
                onError={console.log}
                format="yyyy-MM-dd HH:mm:ss"
              />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
              <InputLabel >Estado del video</InputLabel>
              <Select name="videoStatus" defaultValue={video ? video.videoStatus : "1"} required>
                <MenuItem value="1">Activo</MenuItem>
                <MenuItem value="2">Inactivo</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl} style={{marginLeft: '0px'}}>
              <TextField
                className={classes.formControl}
                margin="dense"
                name="countryRestriction"
                label="Restricción de País"
                type="text"
                fullWidth
                defaultValue={video ? video.countryRestriction : ""}
              />
              <FormHelperText id="helper-text" style={{marginLeft: '8px'}}>PE, AR, CL, etc...</FormHelperText>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="director"
              label="Director"
              type="text"
              fullWidth
              required
              defaultValue={video ? video.director : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="synopsis"
              label="Sinopsis"
              type="text"
              fullWidth
              required
              defaultValue={video ? video.synopsis : ""}
            />
            <FormControl className={classes.formControl} style={{display: 'inline-block'}}>
              Video:{' '}<FileUpload name='movie' onSelect={setMovieUpload} onDelete={deleteMovie} url={movie} />
            </FormControl>
            <FormControl className={classes.formControl} style={{display: 'inline-block'}}>
              Trailer:{' '}<FileUpload name='trailer' onSelect={setTrailerUpload} onDelete={deleteTrailer} url={trailer} />
            </FormControl>
            <FormControl className={classes.formControl} style={{display: 'inline-block'}}>
              Imágenes:{' '}
              <Button color="primary" variant="contained" component="span" type="button" onClick={()=>setImages([...images, null])}>
                Nueva Imágen
              </Button>
              <div style={{display: 'block'}}>
              {images.map((image, index)=>(
                <FileUpload key={index} deletable name='images' onDelete={deleteImage} url={image} onSelect={(image)=>{
                  if (image){
                    setImagesUpload([...imagesUpload, image])
                  }
                }} />
              ))}
              </div>
            </FormControl>
            <FormControl className={classes.formControl} style={{display: 'inline-block'}}>
              Fotografía de Director (1 por director):{' '}
              <Button color="primary" variant="contained" component="span" type="button" onClick={()=>setDirectorImages([...directorImages, null])}>
                Nueva Imágen
              </Button>
              <div style={{display: 'block'}}>
              {directorImages.map((image, index)=>(
                <FileUpload key={index} deletable name='images' onDelete={deleteDirectorImage} url={image} onSelect={(image)=>{
                  if (image){
                    setDirectorImagesUpload([...directorImagesUpload, image])
                  }
                }} />
              ))}
              </div>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="comments"
              label="Comentarios"
              type="text"
              fullWidth
              defaultValue={video ? video.comments : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <CircularProgressModal value={progressValue} open={progressDialogOpen} />
    </div>
  );
}