import React, {useState, useEffect} from 'react';

import AddBoxIcon from '@material-ui/icons/AddBox';
import DeleteIcon from '@material-ui/icons/Delete';
import GetAppIcon from '@material-ui/icons/GetApp';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Card from '@material-ui/core/Card';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {DropzoneDialog} from 'material-ui-dropzone';
import AttachmentUseCases from '../../domain/usecases/AttachmentUseCases';

export default function WorkerAttachmentsModal({show, setShow, worker}) {

  const [attachments, setAttachments] = useState([]);
  const [showAttachmentsModal, setShowAttachmentsModal] = useState(false);
  
  const loadAttachments = async () => {
    if (worker){
        const attachmentUseCases = new AttachmentUseCases();
        setAttachments(await attachmentUseCases.getAllByWorker(worker.id));
    }
  };

  useEffect(()=>{
    loadAttachments();
  }, [worker]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    await loadAttachments();
    handleClose();
  };

  const handleDelete = async e => {
    await new AttachmentUseCases().delete(e);
    loadAttachments();
  };

  const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Adjuntos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Button variant="contained" color="primary" onClick={()=>{
                  setShowAttachmentsModal(true);
              }}><AddBoxIcon /> Nuevo adjunto</Button>
            </DialogContentText>
            <TableContainer component={Card}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Archivo</TableCell>
                            <TableCell align="right">Fecha</TableCell>
                            <TableCell align="right">Subido por</TableCell>
                            <TableCell align="right">Descargar</TableCell>
                            <TableCell align="right">Eliminar</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {attachments.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                    <a href={baseURL + "/storage/app/" + row.url} target="_blank">{row.id + "." + row.url.split(".")[1]}</a>
                                </TableCell>
                                <TableCell align="right">{row.created_at.toLocaleString()}</TableCell>
                                <TableCell align="right">{row.user.firstName} {row.user.lastName}</TableCell>
                                <TableCell align="right">
                                    <a href={baseURL + "/storage/app/" + row.url} download><GetAppIcon /></a>
                                </TableCell>
                                <TableCell align="right">
                                    <a href="#!" onClick={async()=>handleDelete(row)}><DeleteIcon/></a>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <DropzoneDialog
        open={showAttachmentsModal}
        onSave={async (files)=>{
            if (files && files.length>0){
                const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
                const attachmentUseCases = new AttachmentUseCases();
                for (let i=0; i<files.length; i++){
                    await attachmentUseCases.saveWorker(files[i], worker.id, loggedUser.id);
                }
                setShowAttachmentsModal(false);
                loadAttachments();
            }
        }}
        acceptedFiles={[
            'image/jpeg', 'image/png', 'image/bmp', 
            'application/msword', 
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'application/vnd.ms-powerpoint',
            'application/vnd.openxmlformats-officedocument.presentationml.presentation',
            'application/pdf'
        ]}
        showPreviews={true}
        maxFileSize={5000000}
        onClose={()=>{
            setShowAttachmentsModal(false);
        }}
        dropzoneText="Arrastra un archivo o haz click aquí"
        previewText="Archivos a adjuntar"
        dialogTitle="Adjuntar archivos"
        cancelButtonText="Cancelar"
        submitButtonText="Adjuntar"
      />
    </div>
  );
}