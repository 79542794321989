import React, {useState, useEffect} from 'react';
import './Movie.css';

import Rating from '@material-ui/lab/Rating';
import { useParams } from "react-router";
import { getMovie, vote } from './repositories/MoviesRepository';
import { getConfig } from './repositories/ConfigRepository';
import { getIPInfo } from './repositories/IPRepository';
import ImageList from './components/ImageList';

import mascot from './resources/feather.png';
import logo from './resources/logo.png';
import directorImage from './resources/director.png';
import {MdArrowBack, MdMovie} from 'react-icons/md';
import {BsFillPlayFill} from 'react-icons/bs';
import {RiAwardFill} from 'react-icons/ri';
import {FaWindowClose} from 'react-icons/fa';

import {withRouter, useLocation} from 'react-router-dom';
import {useSpring, animated} from 'react-spring';
import ReactModal from 'react-modal';
import videojs from 'video.js';

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

function Movie(props){
    const { history } = props;
    const query = useQuery();
    const fadeIn = useSpring({opacity: 1, from: {opacity: 0}});
    const rightSlideIn = useSpring({transform: 'translate3d(0,0,0)', from: {transform: 'translate3d(100%,0,0)'}});
    const leftSlideIn = useSpring({transform: 'translate3d(0,0,0)', from: {transform: 'translate3d(-100%,0,0)'}});
    const id = useLocation().pathname.split('/')[2];
    const [movie, setMovie] = useState({});
    const [images, setImages] = useState([]);
    const [directors, setDirectors] = useState([]);
    const [config, setConfig] = useState({});
    const [geoblocked, setGeoblocked] = useState(false);
    const [location, setLocation] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    useEffect(()=>{
        let player = null;
        const fetchMovie = async () => {
            const movie = await getMovie(id);
            setMovie(movie);
            if (movie.images){
                const imageSplit = movie.images.split(",");
                const arrImages = [];
                for (let i=0; i<imageSplit.length; i++){
                    const image = imageSplit[i].split("|");
                    arrImages.push({
                        src: image[0],
                        title: image.length > 1 ? image[1] : ""
                    });
                }
                setImages(arrImages);
            }
            if (movie.director){
                const directorSplit = movie.director.split(",");
                //const directorBioSplit = movie.directorBio.split("|");
                const arrDirectors = [];
                for (let i=0; i<directorSplit.length; i++){
                    arrDirectors.push({
                        name: directorSplit[i],
                        bio: null//directorBioSplit[i]
                    });
                }
                setDirectors(arrDirectors);
            }
            const config = await getConfig();
            setConfig(config);
            let location = null;
            const ipInfoParts = (await getIPInfo()).split("\n");
            ipInfoParts.forEach(ipInfoPart => {
                const rawValue = ipInfoPart.split("=");
                const key = rawValue[0];
                const value = rawValue[1];
                if (key === "loc"){
                    setLocation(value);
                    location = value;
                }
            });
            try{
                player = videojs("video_movie", {}, function(){});
                if (config.enableVideos && config.enableVideos === true && 
                    (   (location && movie.countryRestriction && !movie.countryRestriction.split(',').includes(location)) //|| 
                        //(query && query.get("movie") && query.get("movie") === 'force')
                        )
                    ){
                    player.src({
                        src: `/video/${movie._id}/movie/playlist.m3u8`,
                        type: 'application/x-mpegURL',
                    });
                }else{
                    setGeoblocked(true);
                }
            }catch(error){}
        };
        fetchMovie();
        return () => {
            if (player){
                player.dispose();
            }
        };
    }, [])
    //const AnimatedMovieList = animated(MovieList);
    const modalStyles = {
        content : {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 99,
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start"
        }
    };
    const width = "80vmin";
    return (
        <div className="moviepage">
            <div style={{display: "flex", justifyContent:"space-between"}}>
                <animated.div style={{...fadeIn, ...leftSlideIn, marginTop: "auto", marginBottom: "auto", marginLeft: "2vmin"}}>
                    <div style={{padding: "10px", width: "4vmin", height: "4vmin", backgroundColor: "#fecc0d", borderRadius: "50%"}} onClick={()=>history.push("/list")}>
                        <MdArrowBack style={{color: "black"}} />
                    </div>
                </animated.div>
                <animated.div style={{...fadeIn, ...rightSlideIn}}>
                    <img src={mascot} className="logo" alt="mascot" />
                    <img src={logo} className="logo" alt="logo" />
                </animated.div>
            </div>
            <br/>
            <div style={{width, margin: "auto", display: "flex", justifyItems: "flex-start", flexDirection: "row"}}><b>{movie.title}</b></div>
            <div style={{width, margin: "auto", display: "flex", justifyItems: "stretch", flexDirection: "row", fontSize: "14px", textAlign: "left", textJustify: "auto"}}><div dangerouslySetInnerHTML={{__html: movie.synopsis}}/></div>
            { images.length > 0 && (
                <div style={{marginTop: "2vmin", marginBottom: "2vmin", width, marginLeft: "auto", marginRight: "auto", display: "flex", justifyItems: "stretch", flexDirection: "row", fontSize: "14px", textAlign: "left", textJustify: "auto"}}><ImageList images={images} /></div>
            )}
            <div style={{marginTop: "2vmin", marginBottom: "2vmin", width, marginLeft: "auto", marginRight: "auto", display: "flex", justifyItems: "flex-end", flexDirection: "row-reverse", fontSize: "14px", textAlign: "left", textJustify: "auto", flexWrap: "wrap", justifyContent: "space-between"}}>
                {/*<div>
                    <div style={{margin: "auto", marginBottom: "3px", display: "flex", justifyItems: "stretch", flexDirection: "row-reverse", fontSize: "18px", textAlign: "left", textJustify: "auto"}}><b>FESTIVALES</b></div>
                    { movie.festivals && movie.festivals.split("|").map(festival=><div key={festival} style={{margin: "auto", display: "flex", justifyItems: "stretch", flexDirection: "row-reverse", fontSize: "14px", textAlign: "left", textJustify: "auto"}}><RiAwardFill style={{margin: "3px"}} />{festival}</div>)}
                </div>*/}
                { movie.trailer && movie.trailer !== "null" && (
                    <div>
                        <button style={{padding: "2vmin", backgroundColor: "#f07625", border: "none", borderRadius: "20px", display: "flex", justifyContent: "center", verticalAlign: "middle", alignItems: "center", }} onClick={()=>setModalOpen(true)}><BsFillPlayFill style={{fontSize: "20"}} />&nbsp;<b>VER TRAILER</b>&nbsp;&nbsp;</button>
                    </div>
                )}
            </div>
            <br/>
            <div style={{display: geoblocked ? 'none' : 'flex', justifyContent: 'center', alignItems: 'center'}}>
                <div style={{width, minHeight: '400px'}}>
                    <video id="video_movie" className="video-js vjs-default-skin" controls preload="false" data-setup='{"fluid": true, "aspectRatio": "16:9"}' poster='/movie.png' >
                        {movie && movie._id === '6049a3024e2c2c0013eca505' && (
                            <track kind="captions" src="/sub.es.vtt" srclang="es" label="Spanish" default />
                        )}
                        <p className="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
                    </video>
                    {id !== '604a9f684e2c2c0013eca507' && <div style={{fontSize: "3vmin", visibility:'inline-block'}}>
                        Califica esta película: <Rating name="size-large" defaultValue={1} size="large" onChange={(event, number)=>{
                            vote(id, number);
                        }} />
                    </div>}
                </div>
            </div>
            {/* config.enableVideos && config.enableVideos === true && ((location && location !== "PE") || (query && query.get("movie") && query.get("movie") === 'force')) && (
                <iframe title={movie.title} src={"https://peertube.nlcode.com/videos/embed/" + movie.video + "?peertubeLink=0"} style={{minHeight: "400px", width}} frameBorder="0" sandbox="allow-same-origin allow-scripts" allowFullScreen="allowfullscreen"></iframe>
            )*/}
            <br/>
            <div style={{width, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto'}}>
                { directors.map((director,index)=>
                    <div key={director.name} style={{width, marginLeft: "auto", marginRight: "auto", marginTop: "20px", display: "flex", justifyItems: "flex-start", flexDirection: "row", fontSize: "18px"}}>
                        {movie.directorImages && movie.directorImages !== 'null' &&
                            <img src={`${movie.directorImages.split(',')[index]}`} alt="director" style={{width: "100px", height: "100px", borderRadius: "50%", objectFit: "cover"}} />
                        }
                        {!movie.directorImages || movie.directorImages === 'null' &&
                            <img src={directorImage} alt="director" style={{width: "50px", height: "50px", borderRadius: "50%", objectFit: "cover"}} />
                        }
                        <div style={{marginLeft: "2vmin", display: "flex", flexDirection: "column", justifyItems: "flex-start"}}>
                            <div style={{display: "flex", flexDirection: "row", justifyItems: "flex-start"}}>
                                <b>{director.name}</b>
                            </div>
                            {director.bio && (
                                <div style={{display: "flex", flexDirection: "row", justifyItems: "flex-start", fontSize: "14px", textAlign: "left", textJustify: "auto"}} dangerouslySetInnerHTML={{__html: director.bio}} />
                            )}
                            {/*<div style={{display: "flex", flexDirection: "row", justifyItems: "flex-start", width: "100%", fontSize: "14px", textAlign: "left", textJustify: "auto"}} ><b>FILMOGRAFÍA</b></div>
                            {movie.directorFilmography.split("|").map(film=><div key={film} style={{display: "flex", flexDirection: "row", justifyItems: "flex-start", width: "100%", fontSize: "14px", textAlign: "left", textJustify: "auto"}}><MdMovie />&nbsp;{film}</div>)}*/}
                        </div>
                    </div>
                )}
            </div>
            { movie.description && movie.description.trim().length > 0 && (
                <div style={{marginTop: "2vmin", marginBottom: "2vmin", width, marginLeft: "auto", marginRight: "auto", display: "flex", justifyItems: "stretch", flexDirection: "row", fontSize: "14px", textAlign: "left", textJustify: "auto"}}><div dangerouslySetInnerHTML={{__html: movie.description}}/></div>
            )}
            <br/>
            <ReactModal
                onRequestClose={()=>setModalOpen(false)}
                style={modalStyles}
                isOpen={modalOpen}
                contentLabel="Minimal Modal Example"
                overlayClassName="Overlay"
                ariaHideApp={false}
            >
                <video id="video_trailer" className="video-js vjs-default-skin" controls preload="auto" width={width} style={{minHeight: "400px", width: '100%'}} >
                    <p className="vjs-no-js">To view this video please enable JavaScript, and consider upgrading to a web browser that <a href="http://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a></p>
                </video>
                <button onClick={()=>{setModalOpen(false)}} style={{border: "none", fontSize: "5vmin", backgroundColor: "white"}}><FaWindowClose /></button>
            </ReactModal>
        </div>
    );
}

export default withRouter(Movie);