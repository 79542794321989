import React from 'react';
import './App.css';
import { FaFacebookSquare } from 'react-icons/fa';
import {
  Switch,
  Route
} from "react-router-dom";
import Home from './Home';
import List from './List';
import Movie from './Movie';

import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles'
import LoginPage from './admin/screens/LoginPage';
import MainPage from './admin/screens/MainPage';
import PaymentsPage from './admin/screens/PaymentsPage';
import CategoriesPage from './admin/screens/CategoriesPage';
import CouponsPage from './admin/screens/CouponsPage';
import TextsPage from './admin/screens/TextsPage';
import UsersPage from './admin/screens/UsersPage';
import VideosPage from './admin/screens/VideosPage';
import { red } from '@material-ui/core/colors';

const theme = createMuiTheme({
  palette: {
    type: 'dark',
    secondary: {
      main: red[900]
    },
    primary: {
      main: red[500]
    }
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '"Lato"',
      'sans-serif'
    ].join(',')
  }
});

function App() {
  return (
    <Switch>
      <Route path="/admin">
        <ThemeProvider theme={theme}>
          <Switch>
            <Route exact path="/admin" render={props => (<LoginPage {...props} />)} />
            <Route exact path="/admin/dashboard" render={props => (<MainPage {...props} />)} />
            <Route exact path="/admin/payments" render={props => (<PaymentsPage {...props} />)} />
            <Route exact path="/admin/categories" render={props => (<CategoriesPage {...props} />)} />
            <Route exact path="/admin/coupons" render={props => (<CouponsPage {...props} />)} />
            <Route exact path="/admin/texts" render={props => (<TextsPage {...props} />)} />
            <Route exact path="/admin/users" render={props => (<UsersPage {...props} />)} />
            <Route exact path="/admin/videos" render={props => (<VideosPage {...props} />)} />
          </Switch>
        </ThemeProvider>
      </Route>
      <Route path="/">
        <div className="App">
          <header className="App-content">
            <Switch>
              <Route path="/movie/:id">
                <Movie />
              </Route>
              <Route path="/list">
                <List />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </header>
          {/*<footer className="App-footer">
            Informes e inscripciones: &nbsp;&nbsp;&nbsp;
            <a href="https://www.facebook.com/Fecit.pe"><FaFacebookSquare /> Festival de Cine de Trujillo</a> &nbsp;&nbsp;&nbsp;
            <a href="mailto:inscripciones.fct@gmail.com">inscripciones.fct@gmail.com</a>
          </footer>*/}
        </div>
      </Route>
    </Switch>
  );
}

export default App;