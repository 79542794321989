
const baseURL =`${window.location.protocol}//${window.location.hostname}`;

export default class UserUseCases {

    static #accessToken = null;

    async login(username, password){
        const response = await fetch(`${baseURL}/api/user/login`, {
            method: 'post', 
            body: JSON.stringify({username, password}),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    async getAll(){
        const response = await fetch(`${baseURL}/api/user`, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await this.getToken()}`
            }
        });
        const data = await response.json();
        return data.data;
    }

    async save(user){
        const response = await fetch(`${baseURL}/api/user`, {
            method: 'post', 
            body: JSON.stringify(user),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await this.getToken()}`
            }
        });
        return await response.json();
    }

    async delete(user){
        const response = await fetch(`${baseURL}/api/user/${user.username}`, {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${await this.getToken()}`
            }
        });
        return await response.json();
    }

    async getToken(){
        if (!UserUseCases.#accessToken || (UserUseCases.#accessToken.exp * 1000) < Date.now()){
            const response = await fetch(`${baseURL}/api/user/token`, {
                method: 'get'
            });
            UserUseCases.#accessToken = (await response.json()).data;
        }
        return UserUseCases.#accessToken;
    }
}