const completeData = async (movie) => {
    movie.thumbnail = `/video/${movie._id}/thumb.png`;
    return movie;
}

export const fetchMovies = async () => {
    const baseURL = `${window.location.protocol}//${window.location.hostname}`;
    try {
        var url = new URL('/api/video', baseURL);
        var params = {videoStatus: `{"$eq":1}`};
        url.search = new URLSearchParams(params).toString();

        const response = await fetch(url, {
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAuth()}`
            }
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
    return null;
}

const getAuth = async () => {
    try {
        const response = await fetch(`/api/user/login`, {
            method: 'post'
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
    return null;
}

export const getMovies = async (filters, moviesCache) => {
    const movies = moviesCache ?? await fetchMovies();
    movies.forEach(movie=>{
        completeData(movie);
    });
    const f = movies.filter(movie=>{
        let filter = true;
        if (filters){
            if (filters.shortFilm === false || filters.shortFilm === true){
                filter = filter && movie.shortFilm === filters.shortFilm;
            }
            if (filters.category){
                filter = filter && movie.category === filters.category;
            }
        }
        return filter;
    });
    return f;
}

export const getMovie = async (id) => {
    return completeData((await fetchMovies()).find(movie=> movie._id === id));
}

export const vote = async (movie, value) => {
    //const baseURL = `${window.location.protocol}//${window.location.hostname}`;
    try {
        const response = await fetch('/api/vote', {
            method: 'post',
            body: JSON.stringify({movie, value}),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${await getAuth()}`
            }
        });
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json.data;
    } catch (error) {
        console.log(error);
    }
    return null;
}