import React, {useState, useEffect, useCallback} from 'react';
import './List.css';
import MovieList from './components/MovieList';

import {getMovies, fetchMovies as getMoviesCache} from './repositories/MoviesRepository';

import mascot from './resources/feather.png';
import logo from './resources/logo.png';
import {MdArrowBack} from 'react-icons/md';

import {withRouter} from 'react-router-dom';
import {useSpring, animated} from 'react-spring';

function List(props){
    const { history } = props;
    const fadeIn = useSpring({opacity: 1, from: {opacity: 0}});
    const leftSlideIn = useSpring({transform: 'translate3d(0,0,0)', from: {transform: 'translate3d(-100%,0,0)'}});
    const rightSlideIn = useSpring({transform: 'translate3d(0,0,0)', from: {transform: 'translate3d(100%,0,0)'}});
    const [miradas, setMiradas] = useState([]);
    const [cortosPeruanos, setCortosPeruanos] = useState([]);
    const [cortosLatinoamericanos, setCortosLatinoamericanos] = useState([]);
    const [largometrajesPeruanos, setLargometrajesPeruanos] = useState([]);
    const [largometrajesLatinoamericanos, setLargometrajesLatinoamericanos] = useState([]);
    const [guardianesDeLaLengua, setGuardianesDeLaLengua] = useState([]);
    useEffect(()=>{
        const fetchMovies = async () => {
            const moviesCache = await getMoviesCache();
            setMiradas(await getMovies({category: "miradas"}, moviesCache));
            setCortosPeruanos(await getMovies({shortFilm: true, category: "peruano"}, moviesCache));
            setCortosLatinoamericanos(await getMovies({shortFilm: true, category: "latinoamericano"}, moviesCache));
            setLargometrajesPeruanos(await getMovies({shortFilm: false, category: "peruano"}, moviesCache));
            setLargometrajesLatinoamericanos(await getMovies({shortFilm: false, category: "latinoamericano"}, moviesCache));
            setGuardianesDeLaLengua(await getMovies({category: "guardianesDeLaLengua"}, moviesCache));
        };
        fetchMovies();
        return  ()=>{
            setMiradas([]);
            setCortosPeruanos([]);
            setCortosLatinoamericanos([]);
            setLargometrajesLatinoamericanos([]);
            setLargometrajesPeruanos([]);
            setGuardianesDeLaLengua([]);
        }
    }, []);
    const CategoryVideos = useCallback(({videos, title}) => {
        return (
            <div>
                <animated.h1 style={{...leftSlideIn, ...fadeIn}}>{title}</animated.h1>
                <MovieList style={{...rightSlideIn, ...fadeIn}} movies={videos} />
            </div>
        )
    }, []);
    return (
        <div className="listpage">
            <div style={{display: "flex", justifyContent:"space-between"}}>
                <animated.div style={{...fadeIn, ...leftSlideIn, marginTop: "auto", marginBottom: "auto", marginLeft: "2vmin"}}>
                    <div  className="floatleft" style={{padding: "10px", width: "4vmin", height: "4vmin", backgroundColor: "#fecc0d", borderRadius: "50%"}} onClick={()=>history.push("/")}>
                        <MdArrowBack style={{color: "black"}} />
                    </div>
                </animated.div>
                <animated.div style={{...fadeIn, ...rightSlideIn}}>
                    <img src={mascot} className="logo" alt="mascot" />
                    <img src={logo} className="logo" alt="logo" />
                </animated.div>
            </div>
            <br/>
            <br/>
            {/*<CategoryVideos videos={cortosPeruanos} title="Cortometrajes Peruanos" />
            <CategoryVideos videos={cortosLatinoamericanos} title="Cortometrajes Latinoamericanos" />
            <CategoryVideos videos={largometrajesPeruanos} title="Largometrajes Peruanos" />
            <CategoryVideos videos={largometrajesLatinoamericanos} title="Largometrajes Latinoamericanos" />
            <CategoryVideos videos={miradas} title="Miradas Latinoamericanas" />
            <CategoryVideos videos={guardianesDeLaLengua} title="Guardianes de la Lengua" />*/}
        </div>
    );
}

export default withRouter(List);