import React, {useState} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/core/styles';
import ClientUseCases from '../../domain/usecases/TextUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value;
      }
  }));
  return formData;
}

export default function ClientModal({show, setShow, client}) {
  const classes = useStyles();
  const [blacklisted, setBlacklisted] = useState(false);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    data.blacklisted = blacklisted===true ? 1 : 0;
    if (client){
      data.id = client.id;
    }
    console.log(data);
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    const clientUseCases = new ClientUseCases();
    await clientUseCases.save(data, loggedUser.id); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="clientForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Empleadores</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos del empleador<br/>
            </DialogContentText>
            <TextField
              className={classes.formControl}
              autoFocus
              margin="dense"
              name="identificationDocumentNumber"
              label="DNI"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.identificationDocumentNumber : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="firstName"
              label="Nombres"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.firstName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="lastName"
              label="Apellido Paterno"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.lastName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="motherMaidenName"
              label="Apellido Materno"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.motherMaidenName : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="address"
              label="Dirección"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.address : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="addressReferences"
              label="Referencias de dirección"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.addressReferences : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="email"
              label="Email"
              type="text"
              required
              fullWidth
              defaultValue={client ? client.email : ""}
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="blacklisted" checked={blacklisted} onChange={()=>setBlacklisted(!blacklisted)} />
              }
              label="Lista Negra"
            />
            {blacklisted && (
              <TextField
                className={classes.formControl}
                margin="dense"
                name="blacklistedReason"
                label="Motivo para lista negra"
                type="text"
                required
                fullWidth
                defaultValue={client ? client.blacklistedReason : ""}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}