async function getIPInfo(){
    try {
        const response = await fetch(`https://www.cloudflare.com/cdn-cgi/trace`);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.text();
        return json;
    } catch (error) {
        console.log(error);
    }
    return null;
}


export {getIPInfo};