import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardTimePicker} from '@material-ui/pickers';
import OrderUseCases from '../../domain/usecases/OrderUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

export default function OrderModal({show, setShow, order, clients}) {
  console.log(order);
  const classes = useStyles();

  const [startTime, setStartTime] = useState(order ? order.startWorkingTime : new Date(2020,0,1,9,0,0,0));
  const [endTime, setEndTime] = useState(order ? order.endWorkingTime : new Date(2020,0,1,18,0,0,0));
  
  useEffect(()=>{
    if (order){
      setStartTime(order.startWorkingTime);
      setEndTime(order.endWorkingTime);
    }else{
      setStartTime(new Date(2020,0,1,9,0,0,0));
      setEndTime(new Date(2020,0,1,18,0,0,0));
    }
  }, [order]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    data.cleaning = data.cleaning === true ? 1 : 0;
    data.clothesWashing = data.clothesWashing === true ? 1 : 0;
    data.childCaring = data.childCaring === true ? 1 : 0;
    data.elderCaring = data.elderCaring === true ? 1 : 0;
    data.petCaring = data.petCaring === true ? 1 : 0;
    if (order){
      data.id = order.id;
    }
    console.log(data);
    const orderUseCases = new OrderUseCases();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    await orderUseCases.save(data, loggedUser.id); 
    handleClose();
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Pedido</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos del pedido<br/>
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_client-label">Cliente</InputLabel>
              <Select 
                name="id_client" 
                defaultValue={order ? order.id_client : ""}
                required>
                {clients.map(client=>(
                  <MenuItem key={client.id} value={client.id}>{client.firstName} {client.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="sex-label">Sexo requerido</InputLabel>
              <Select name="sex" defaultValue={order ? order.sex : "f"} required>
                <MenuItem value="f">Femenino</MenuItem>
                <MenuItem value="m">Masculino</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="minAge"
              label="Edad mínima"
              type="text"
              fullWidth
              required
              defaultValue={order ? order.minAge : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="maxAge"
              label="Edad máxima"
              type="text"
              fullWidth
              required
              defaultValue={order ? order.maxAge : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="minExperience"
              label="Experiencia mínima"
              type="text"
              fullWidth
              required
              defaultValue={order ? order.minExperience : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="maxExperience"
              label="Experiencia máxima"
              type="text"
              fullWidth
              required
              defaultValue={order ? order.maxExperience : ""}
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="cleaning" defaultChecked={order && order.cleaning === 1 ? true : false} />
              }
              label="Limpieza"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="clothesWashing" defaultChecked={order && order.clothesWashing === 1 ? true : false} />
              }
              label="Lavado de Ropa"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="childCaring" defaultChecked={order && order.childCaring === 1 ? true : false} />
              }
              label="Cuidado de niños"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="elderCaring" defaultChecked={order && order.elderCaring === 1 ? true : false} />
              }
              label="Cuidado de ancianos"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="petCaring" defaultChecked={order && order.petCaring === 1 ? true : false} />
              }
              label="Cuidado de mascotas"
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="salary"
              label="Salario"
              type="text"
              fullWidth
              required
              defaultValue={order ? order.salary : ""}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="shift-label">Turno</InputLabel>
              <Select name="shift" defaultValue={order ? order.shift : "morning"} required>
                <MenuItem value="morning">Mañana</MenuItem>
                <MenuItem value="afternoon">Tarde</MenuItem>
                <MenuItem value="night">Noche</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="startWorkingTime"
                label="Hora de Inicio"
                value={startTime}
                onChange={setStartTime}
                onError={console.log}
                format="HH:mm:ss"
                required
              />
            </MuiPickersUtilsProvider>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardTimePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="endWorkingTime"
                label="Hora de Fin"
                value={endTime}
                onChange={setEndTime}
                onError={console.log}
                format="HH:mm:ss"
                required
              />
            </MuiPickersUtilsProvider>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="numberOfPeople"
              label="Numero de personas a cargo "
              type="text"
              required
              fullWidth
              defaultValue={order ? order.numberOfPeople : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="comments"
              label="Comentarios"
              type="text"
              required
              fullWidth
              defaultValue={order ? order.comments : ""}
            />
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}