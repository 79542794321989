import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DateFnsUtils from '@date-io/date-fns';
import { makeStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider, KeyboardDatePicker} from '@material-ui/pickers';
import AppointmentUseCases from '../../domain/usecases/AppointmentUseCases';
import WorkerUseCases from '../../domain/usecases/CategoryUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

export default function WorkerModal({show, setShow, worker}) {
  const classes = useStyles();

  const [selectedDate, handleDateChange] = useState(worker ? worker.dob : new Date());
  const [blacklisted, setBlacklisted] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [id_appointment, setId_appointment] = useState("");
  const [documentIdentificationNumber, setDocumentIdentificationNumber] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [motherMaidenName, setMotherMaidenName] = useState("");
  const [email, setEmail] = useState("");
  const [id_location_area, setId_location_area] = useState(1);
  const [id_position, setId_position] = useState(1);
  const [id_modality, setId_modality] = useState(1);
  const [experienceYears, setExperienceYears] = useState("");

  const loadAppointments = async () => {
    const appointmentUseCases = new AppointmentUseCases();
    setAppointments(await appointmentUseCases.getUnused());
  };

  useEffect(()=>{
    loadAppointments();
    if (worker){
      setId_appointment(worker.id_appointment ? worker.id_appointment : "");
      setDocumentIdentificationNumber(worker.identityDocumentNumber);
      setFirstName(worker.firstName);
      setLastName(worker.lastName);
      setMotherMaidenName(worker.motherMaidenName);
      setEmail(worker.email);
      setId_location_area(worker.id_location_area);
      setId_position(worker.id_position);
      setId_modality(worker.id_modality);
      setExperienceYears(worker.experienceYears);
    } else {
      setDocumentIdentificationNumber("");
      setFirstName("");
      setLastName("");
      setMotherMaidenName("");
      setEmail("");
      setId_location_area(1);
      setId_position(1);
      setId_modality(1);
      setExperienceYears("");
    }
  }, [worker]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    const data = getFormData(e.target);
    if (data.id_appointment === "")
      data.id_appointment = undefined;
    data.blacklisted = blacklisted===true ? 1 : 0;
    data.cleaning = data.cleaning === true ? 1 : 0;
    data.clothesWashing = data.clothesWashing === true ? 1 : 0;
    data.childCaring = data.childCaring === true ? 1 : 0;
    data.elderCaring = data.elderCaring === true ? 1 : 0;
    data.petCaring = data.petCaring === true ? 1 : 0;
    if (worker){
      data.id = worker.id;
    }
    const workerUseCases = new WorkerUseCases();
    const loggedUser = JSON.parse(localStorage.getItem("loggedUser"));
    await workerUseCases.save(data, loggedUser.id);
    await loadAppointments();
    handleClose();
  };

  const handleAppointmentChange = e => {
    const selectedIdAppointment = e.target.value;
    if (selectedIdAppointment !== ""){
      let selectedAppointment = null;
      if (worker && selectedIdAppointment === worker.id_appointment){
        selectedAppointment = worker.appointment;
      }else{
        appointments.forEach(appointment=>{
          if (appointment.id === selectedIdAppointment)
            selectedAppointment = appointment;
        });
      }
      if (selectedAppointment){
        setDocumentIdentificationNumber(selectedAppointment.identityDocumentNumber);
        setFirstName(selectedAppointment.firstName);
        setLastName(selectedAppointment.lastName);
        setMotherMaidenName(selectedAppointment.motherMaidenName);
        setEmail(selectedAppointment.email);
        setId_location_area(selectedAppointment.id_location_area);
        setId_position(selectedAppointment.id_position);
        setId_modality(selectedAppointment.id_modality);
        setExperienceYears(selectedAppointment.experienceYears);
      }
    }
    setId_appointment(selectedIdAppointment);
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">Trabajador</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Ingresa los datos del trabajador<br/>
            </DialogContentText>
            <FormControl className={classes.formControl}>
              <InputLabel id="sex-label">Cita</InputLabel>
              <Select 
                name="id_appointment" 
                value={id_appointment}
                onChange={handleAppointmentChange} 
                required>
                {appointments.concat(worker && worker.appointment ? [worker.appointment] : []).map(appointment=>(
                  <MenuItem key={appointment.id} value={appointment.id}>{appointment.firstName} {appointment.lastName}</MenuItem>  
                ))}
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              autoFocus
              margin="dense"
              name="identityDocumentNumber"
              label="DNI"
              type="text"
              required
              fullWidth
              value={documentIdentificationNumber}
              onChange={e=>setDocumentIdentificationNumber(e.target.value)}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="firstName"
              label="Nombres"
              type="text"
              required
              fullWidth
              value={firstName}
              onChange={e=>setFirstName(e.target.value)}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="lastName"
              label="Apellido Paterno"
              type="text"
              required
              fullWidth
              value={lastName}
              onChange={e=>setLastName(e.target.value)}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="motherMaidenName"
              label="Apellido Materno"
              type="text"
              required
              fullWidth
              value={motherMaidenName}
              onChange={e=>setMotherMaidenName(e.target.value)}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="email"
              label="Email"
              type="text"
              required
              fullWidth
              value={email}
              onChange={e=>setEmail(e.target.value)}
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="sex-label">Sexo</InputLabel>
              <Select name="sex" defaultValue={worker ? worker.sex : "f"} required>
                <MenuItem value="f">Femenino</MenuItem>
                <MenuItem value="m">Masculino</MenuItem>
              </Select>
            </FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.formControl}
                variant="inline"
                ampm={false}
                name="dob"
                label="Fecha de Nacimiento"
                value={selectedDate}
                onChange={handleDateChange}
                onError={console.log}
                format="yyyy-MM-dd"
              />
            </MuiPickersUtilsProvider>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_location_area-label">Zona</InputLabel>
              <Select 
                name="id_location_area" 
                value={id_location_area}
                onChange={e=>setId_location_area(e.target.value)} 
                required>
                <MenuItem value={1}>Trujillo</MenuItem>
                <MenuItem value={2}>El Porvenir</MenuItem>
                <MenuItem value={3}>Florencia de Mora</MenuItem>
                <MenuItem value={4}>Huanchaco</MenuItem>
                <MenuItem value={5}>La Esperanza</MenuItem>
                <MenuItem value={6}>Laredo</MenuItem>
                <MenuItem value={7}>Moche</MenuItem>
                <MenuItem value={8}>Salaverry</MenuItem>
                <MenuItem value={9}>Simbal</MenuItem>
                <MenuItem value={10}>Victor Larco Herrera</MenuItem>
                <MenuItem value={11}>El Milagro</MenuItem>
                <MenuItem value={12}>Alto Trujillo</MenuItem>
                <MenuItem value={13}>Otros - Este</MenuItem>
                <MenuItem value={14}>Otros - Norte</MenuItem>
                <MenuItem value={15}>Otros - Sur</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="address"
              label="Dirección"
              type="text"
              required
              fullWidth
              defaultValue={worker ? worker.address : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="addressReferences"
              label="Referencia de Dirección"
              type="text"
              required
              fullWidth
              defaultValue={worker ? worker.addressReferences : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="experienceYears"
              label="Años de Experiencia"
              type="text"
              required
              fullWidth
              value={experienceYears}
              onChange={e=>setExperienceYears(e.target.value)}
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="cleaning" defaultChecked={worker && worker.cleaning === 1 ? true : false} />
              }
              label="Limpieza"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="clothesWashing" defaultChecked={worker && worker.clothesWashing === 1 ? true : false} />
              }
              label="Lavado de Ropa"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="childCaring" defaultChecked={worker && worker.childCaring === 1 ? true : false} />
              }
              label="Cuidado de niños"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="elderCaring" defaultChecked={worker && worker.elderCaring === 1 ? true : false} />
              }
              label="Cuidado de ancianos"
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="petCaring" defaultChecked={worker && worker.petCaring === 1 ? true : false} />
              }
              label="Cuidado de mascotas"
            />
            <FormControl className={classes.formControl}>
              <InputLabel id="preferredShift-label">Turno preferido</InputLabel>
              <Select name="preferredShift" defaultValue={worker ? worker.preferredShift : "morning"} required>
                <MenuItem value="morning">Mañana</MenuItem>
                <MenuItem value="afternoon">Tarde</MenuItem>
                <MenuItem value="night">Noche</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_position-label">Puesto</InputLabel>
              <Select 
                name="id_position" 
                value={id_position} 
                onChange={e=>setId_position(e.target.value)}
                required>
                <MenuItem value={1}>Nana</MenuItem>
                <MenuItem value={2}>Nana con apoyo en casa</MenuItem>
                <MenuItem value={3}>Nana - TH</MenuItem>
                <MenuItem value={4}>Trabajadora del Hogar</MenuItem>
                <MenuItem value={5}>Niñera</MenuItem>
                <MenuItem value={6}>Niñera con apoyo en casa</MenuItem>
                <MenuItem value={7}>Niñera - TH</MenuItem>
                <MenuItem value={8}>Limpieza</MenuItem>
                <MenuItem value={9}>Enfermera Geriátrica</MenuItem>
                <MenuItem value={10}>Enfermera Pediátrica</MenuItem>
                <MenuItem value={11}>Cuidadora de Ancianos</MenuItem>
              </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
              <InputLabel id="id_modality-label">Modalidad</InputLabel>
              <Select 
                name="id_modality" 
                value={id_modality} 
                onChange={e=>setId_modality(e.target.value)}
                required>
                <MenuItem value={1}>Interna (Cama adentro)</MenuItem>
                <MenuItem value={2}>Externa (Cama afuera)</MenuItem>
                <MenuItem value={3}>Part time (Medio tiempo)</MenuItem>
                <MenuItem value={4}>Por días</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="numberOfPeople"
              label="Numero de personas a cargo "
              type="text"
              required
              fullWidth
              defaultValue={worker ? worker.numberOfPeople : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="comments"
              label="Comentarios"
              type="text"
              required
              fullWidth
              defaultValue={worker ? worker.comments : ""}
            />
            <FormControlLabel className={classes.formControl}
              control={
                <Checkbox name="blacklisted" checked={blacklisted} onChange={()=>setBlacklisted(!blacklisted)} />
              }
              label="Lista Negra"
            />
            {blacklisted && (
              <TextField
                className={classes.formControl}
                margin="dense"
                name="blacklistedReason"
                label="Motivo para lista negra"
                type="text"
                required
                fullWidth
                defaultValue={worker ? worker.blacklistedReason : ""}
              />
            )}
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}