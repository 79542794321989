import React, { useState } from 'react';
import {Button} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default ({url, onDelete, name, deletable, onSelect})=>{
    const [id] = useState(Date.now());
    const [deleted, setDeleted] = useState(false);
    const [selected, setSelected] = useState(false);
    const [selectedFile, setSelectedFile] = useState(null);
    return deleted ? null : (
        <label htmlFor={ name + id} style={{display: 'inline-block'}}>
            <input
                style={{ display: "none" }}
                id={name+id}
                name={name}
                type="file"
                onChange={() => {
                    setSelected(true);
                    if (onSelect){
                        const domInput = document.getElementById(name+id);
                        setSelectedFile(domInput.files[0]);
                        onSelect(domInput.files[0]);
                    }
                }}
            />
            {url &&
                <Button color="secondary" variant="contained" component="span" disabled>
                    {url}
                </Button>
            }
            {!url && <> 
                {!selected && 
                    <Button color="secondary" variant="contained" component="span">
                        Elegir archivo
                    </Button>
                }
                {selected && 
                    <Button color="secondary" variant="contained" component="span" disabled>
                        Archivo elegido
                    </Button>
                }
                </>
            }
            <a 
                href="#!" 
                onClick={()=>{
                    const domInput = document.getElementById(name+id);
                    domInput.value = null;
                    setSelected(null);
                    if (deletable){
                        setDeleted(true);
                    }
                    if (url && onDelete){
                        onDelete(url);
                    }
                    if (selectedFile && onDelete){
                        onDelete(selectedFile);
                    }
                }}>
                <DeleteIcon color="secondary" />
            </a>
        </label>
    );
};