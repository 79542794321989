import React, {useRef, useState} from 'react';
import './MovieList.css';
import {FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import {BsFillPlayFill} from 'react-icons/bs';
import {withRouter} from 'react-router-dom';

import {vmin} from "../util/vCalc";

import {animated} from 'react-spring';

let m_jump = false;
let m_jumpTarget = -1;
let m_scrollPosition = 0;
const scrollingSpeed = 10;

function MovieList(movieProps){
    const {movies, style} = movieProps;
    const [jump, setJump] = useState(false);
    const [jumpTarget, setJumpTarget] = useState(-1);
    const [scrollPosition, setScrollPosition] = useState(1);
    const [scrollingInterval, setScrollingInterval] = useState(null);
    m_jump = jump;
    m_jumpTarget = jumpTarget;
    m_scrollPosition = scrollPosition;
    const rowDOM = useRef(null);
    const hoverLeft = (inside) => {
        if (inside){
            m_scrollPosition = rowDOM.current.scrollWidth - rowDOM.current.clientWidth; 
            setScrollPosition(m_scrollPosition);
            if (scrollingInterval){
                clearInterval(scrollingInterval);
            }
            setScrollingInterval(setInterval(()=>{
                if (m_jump && rowDOM.current.scrollLeft <= m_jumpTarget && m_jumpTarget !== -1){
                    setJump(false);
                    setJumpTarget(-1);
                }else{
                    if (!m_jump){
                        // Need frameskip if scroll is not completed
                        if (m_scrollPosition >= rowDOM.current.scrollLeft){    
                            const newScrollPosition = Math.max(rowDOM.current.scrollLeft - scrollingSpeed, 0);
                            setScrollPosition(newScrollPosition);
                            rowDOM.current.scrollLeft = newScrollPosition;
                        }
                    }
                }
            }, 30));
        }else{
            clearInterval(scrollingInterval);
        }
    };
    const hoverRight = (inside) => {
        if (inside){
            m_scrollPosition = 0;
            setScrollPosition(m_scrollPosition);
            if (scrollingInterval){
                clearInterval(scrollingInterval);
            }
            setScrollingInterval(setInterval(()=>{
                if (m_jump && rowDOM.current.scrollLeft >= m_jumpTarget && m_jumpTarget !== -1){
                    setJump(false);
                    setJumpTarget(-1);
                }else{
                    if (!m_jump){
                        // Need frameskip if scroll is not completed
                        if (m_scrollPosition <= rowDOM.current.scrollLeft){
                            const newScrollPosition = Math.min(rowDOM.current.scrollLeft + scrollingSpeed, rowDOM.current.scrollWidth - rowDOM.current.clientWidth);
                            setScrollPosition(newScrollPosition);
                            rowDOM.current.scrollLeft = newScrollPosition;
                        }
                    }
                }
            }, 30));
        }else{
            clearInterval(scrollingInterval);
            setScrollingInterval(null);
        }
    };
    const jumpLeft=()=>{
        if (!jump){
            setJump(true);
            const target = Math.round(Math.max(rowDOM.current.scrollLeft - vmin(26.9), 0));
            setScrollPosition(target);
            setJumpTarget(target);
            rowDOM.current.scrollTo({
                top: 0,
                left: target,
                behaviour: "smooth"
            });
        }
    };
    const jumpRight=()=>{
        if (!jump){
            setJump(true);
            const target = Math.round(Math.min(rowDOM.current.scrollLeft + vmin(26.9), rowDOM.current.scrollWidth - rowDOM.current.clientWidth));
            setScrollPosition(target);
            setJumpTarget(target);
            rowDOM.current.scrollTo({
                top: 0,
                left: target,
                behaviour: "smooth"
            });
        }
    };
    const DelayedLink = props =>
        <div className={props.className} onClick={event=>{
            const { history } = movieProps;
            event.preventDefault();
            setTimeout(()=>history.push(props.to), props.delay);
        }}>
            {props.children}
        </div>;
    return (
        <animated.div style={{...style, width: '100%', position: "relative"}}>
            <div className="scrollLeft" onMouseEnter={() => hoverLeft(true)} onMouseLeave={()=>hoverLeft(false)} onClick={()=>jumpLeft()}>
                <FaChevronLeft className="faChevronLeft" />
            </div>
            <div className="scrollRight" onMouseEnter={()=>hoverRight(true)} onMouseLeave={()=>hoverRight(false)} onClick={()=>jumpRight()}>
                <FaChevronRight className="faChevronRight" />
            </div>
            <div className="row" ref={rowDOM}>
                <div className="row__inner">
                    {movies.map((movie) =>
                        <div key={movie._id} className="tile">
                            <div className="tile__media">
                                <img className="tile__img" src={movie.thumbnail} alt={movie.title}  />
                            </div>
                            <div className="tile__details">
                                <div className="tile__details_before" />
                                <div className="tile__title">
                                    {movie.title}
                                </div>
                                <div className="tile__details_after">
                                    <DelayedLink to={"/movie/"+movie._id} delay={1200} className="play-icon">
                                        <BsFillPlayFill />
                                    </DelayedLink>
                                </div>
                            </div>
                            { movie.premiere && <div className='ribbon-wrapper'><div className='ribbon'>Estreno</div></div> }
                        </div>
                    )};
                </div>
            </div>
        </animated.div>
    );
};
export default withRouter(MovieList);