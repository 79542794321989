import React, {useState, useEffect} from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Alert from '@material-ui/lab/Alert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles } from '@material-ui/core/styles';
//import ServiceOrderUseCases from '../../domain/usecases/ServiceOrderUseCases';
import UserUseCases from '../../domain/usecases/UserUseCases';

const useStyles = makeStyles(theme => ({
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  formInput: {
    margin: theme.spacing(1),
  },
  selectEmpty: {
    // marginTop: theme.spacing(2),
  },
}));

function getFormData(form) {
  const formData = {};
  Object.keys(form).forEach((propertyName => {
      const element = form[propertyName];
      if (element.name && element.name.trim().length > 0){
          formData[element.name] = element.value ? element.value : element.checked;
      }
  }));
  return formData;
}

const createError = (name, message)=>{
  const error = new Error(message);
  error.name = name;
  return error;
}

const getTimeString = (date) => {
  return ("" + date.getHours()).padStart(2, '0') + ":" + ("" + date.getMinutes()).padStart(2, '0') + ":" + ("" + date.getSeconds()).padStart(2, '0');
};

const getDateString = (date) => {
  if (date.getFullYear && date.getMonth && date.getDate){
    return date.getFullYear() + '-' + (""+(date.getMonth()+1)).padStart(2, '0') + '-'+ (""+date.getDate()).padStart(2,'0');
  }else{
    return date;
  }
};

const getDateTimeString = (date) => {
  return getDateString(date) + " " + getTimeString(date);
};

export default function UserModal({show, setShow, user}) {
  const classes = useStyles();

  const [role, setRole] = useState('1');
  const [country, setCountry] = useState('pe');
  const [error, setError] = useState(null);

  useEffect(()=>{
    if (user){
      setRole(user.role);
      setCountry(user.country);
    }else{
      setRole(1);
      setCountry('pe');
    }
  }, [user]);

  const handleClose = () => {
    setShow(false);
  };

  const handleSave = async e => {
    e.preventDefault();
    try{
      const data = getFormData(e.target);
      if (user){
        data._id = user._id;
        if (data.password && data.password.length > 0 && data.password === data.passwordConfirmation){
          delete data.passwordConfirmation;
        }else{
          if (!data.password || data.password.length === 0){
            //password wasnt changed, all good.
          }else{
            throw createError('passwords_dont_match', 'Password confirmation doesn\'t match');
          }
        }
      } else {
        if (!data.password || data.password.trim() === ''){
          throw createError('empty_password', 'Password is empty.');
        }else if(data.password !== data.passwordConfirmation){
          throw createError('passwords_dont_match', 'Password confirmation doesn\'t match');
        }
      }
      const userUseCases = new UserUseCases();
      await userUseCases.save(data);
      setError(null);
      handleClose();
    }catch (e){
      setError(e);
    }
  };

  return (
    <div>
      <Dialog open={show} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form id="workerForm" onSubmit={handleSave} autoComplete="off" >
          <DialogTitle id="form-dialog-title">{user ? 'Editar' : 'Nuevo'} Usuario</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Datos del usuario<br/>
            </DialogContentText>
            {error && (
              <Alert severity="error">{error.message}</Alert>
            )}
            <TextField
              className={classes.formControl}
              margin="dense"
              name="name"
              label="Nombre"
              type="text"
              fullWidth
              required
              defaultValue={user ? user.name : ""}
            />
            <FormControl className={classes.formControl}>
              <InputLabel >Rol</InputLabel>
              <Select name="role" defaultValue={role} onChange={(e)=>{setRole(e.target.value)}} required>
                <MenuItem value="1">Cliente</MenuItem>
                <MenuItem value="100">Administrador</MenuItem>
                <MenuItem value="1000">Super Administrator</MenuItem>
              </Select>
            </FormControl>
            <TextField
              className={classes.formControl}
              margin="dense"
              name="username"
              label="Nombre de usuario"
              type="text"
              fullWidth
              required
              defaultValue={user ? user.username : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="password"
              label="Contraseña"
              type="password"
              fullWidth
              defaultValue={user ? "" : ""}
            />
            <TextField
              className={classes.formControl}
              margin="dense"
              name="passwordConfirmation"
              label="Confirmar Contraseña"
              type="password"
              fullWidth
              defaultValue={user ? "" : ""}
            />
            <FormControl className={classes.formControl}>
              <InputLabel >País</InputLabel>
              <Select name="country" defaultValue={country} onChange={(e)=>{setCountry(e.target.value)}} required>
                <MenuItem value="pe">Peru</MenuItem>
                <MenuItem value="ar">Argentina</MenuItem>
                <MenuItem value="br">Brazil</MenuItem>
                <MenuItem value="ec">Ecuador</MenuItem>
                <MenuItem value="bo">Bolivia</MenuItem>
                <MenuItem value="cl">Chile</MenuItem>
                <MenuItem value="py">Paraguay</MenuItem>
                <MenuItem value="uy">Uruguay</MenuItem>
                <MenuItem value="uy">Colombia</MenuItem>
                <MenuItem value="ve">Venezuela</MenuItem>
                <MenuItem value="sv">El Salvador</MenuItem>
                <MenuItem value="mx">México</MenuItem>
              </Select>
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button color="primary" type="submit">
              Guardar
            </Button>
            <Button onClick={handleClose} color="primary">
              Cancelar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
}