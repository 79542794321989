import React, {useState} from 'react';
import './ImageList.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function ImageList({images}){
    const [isOpen, setIsOpen] = useState(false);
    const [image, setImage] = useState(null);
    return (
        <>
            <section id="photos">
                {images.map(image=><img key={image.src} src={image.src} alt={image.title} onClick={()=>{setIsOpen(true); setImage(image.src)}} />)}
            </section>
            {isOpen && (
                <Lightbox
                    mainSrc={image}
                    onCloseRequest={() => setIsOpen(false)}
                />
            )}
        </>
    );
}

export default ImageList;