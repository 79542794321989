const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class AttachmentUseCases {
    async getAll(idUser){
        const response = await fetch(`${baseURL}/api/attachment/list/${idUser}`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async getAllByWorker(idWorker){
        const response = await fetch(`${baseURL}/api/attachment/list/worker/${idWorker}`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async getAllByContract(idContract){
        const response = await fetch(`${baseURL}/api/attachment/list/contract/${idContract}`, {method: 'get'});
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async saveWorker(file, idWorker, idUser){
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('id_worker', idWorker);
        formData.append('id_user', idUser);
        const response = await fetch(`${baseURL}/api/attachment/save/${idUser}`, {
            method: 'post', 
            body: formData
        });
        return await response.json();
    }

    async saveContract(file, idContract, idUser){
        const formData = new FormData();
        formData.append('attachment', file);
        formData.append('id_contract', idContract);
        formData.append('id_user', idUser);
        const response = await fetch(`${baseURL}/api/attachment/save/${idUser}`, {
            method: 'post', 
            body: formData
        });
        return await response.json();
    }

    async delete(client){
        const response = await fetch(`${baseURL}/api/attachment/delete/${client.id}`, {method: 'delete'});
        return await response.json();
    }
}