import UserUseCases from './UserUseCases';

const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class VideoUseCases {

    constructor() {
        this.userUseCases = new UserUseCases();
    }

    async getAll(since=undefined, until=undefined, active=1){
        const params = [
            {start: since ? `{"$gte":${since}}` : undefined}, 
            {start: until ? `{"$lte":${until}}` : undefined}, 
            {videoStatus: `{"$eq":${active}}`}
        ];
        const response = await fetch(`${baseURL}/api/video${Object.keys(params).length === 0 && params.constructor === Object ? '' : `?${objectArrayToQueryString(params)}`}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${await this.userUseCases.getToken()}`
            }});
        const json = await response.json();
        json.data.forEach(item => {
            item.created_at = new Date(item.created_at);
            item.start = new Date(item.start);
        });
        return json.data;
    }

    save(video, onProgress){
        const formData = new FormData();
        Object.keys(video).forEach(key=>{
            if (video[key] instanceof Array){
                video[key].forEach(item => {
                    formData.append(key, item);
                });
            }else{
                formData.append(key, video[key]);
            }
        });

        /*const response = await fetch(`${baseURL}/api/video`, {
            method: 'post', 
            body: formData,
            headers: {
                //'Content-Type': 'application/json',
                'Authorization': `Bearer ${await this.userUseCases.getToken()}`
            }
        });
        return await response.json();*/
        return new Promise((resolve, reject)=>{
            this.userUseCases.getToken().then(token => {
                const request = new XMLHttpRequest();
                request.open('POST', `${baseURL}/api/video`);
    
                request.setRequestHeader('Authorization', 'Bearer ' + token);
        
                // upload progress event
                request.upload.addEventListener('progress', function(e) {
                    // upload progress as percentage
                    const percent_completed = (e.loaded / e.total)*100;
                    console.log('Upload progress', percent_completed);
                    if (onProgress){
                        onProgress(percent_completed);
                    }
                });
        
                // request finished event
                request.addEventListener('load', function(e) {
                    // HTTP status message (200, 404 etc)
                    //console.log(request.status);
                    // request.response holds response from the server
                    //console.log(request.response);
                    resolve(request.response);
                });
        
                // send POST request to server
                request.send(formData);
            });
        });
    }

    async delete(video){
        const response = await fetch(`${baseURL}/api/video/${video._id}`, {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${await this.userUseCases.getToken()}`
            }});
        return await response.json();
    }
}

function objectArrayToQueryString(arr) {
    return arr.reduce((acumulator, obj)=>{
        return acumulator + (obj ? (acumulator === '' ? '' : '&') + 
            Object.keys(obj).map(key => obj[key] !== undefined ? key + '=' + encodeURIComponent(obj[key]) : undefined).filter(el => el!==undefined).join('&')
            : '');
    }, '');
}