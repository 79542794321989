const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class TextUseCases {
    async getAll(token, blacklisted, active=1){
        const params = {blacklisted, active};
        const response = await fetch(`${baseURL}/api/texts?${objectToQueryString(params)}`, {
            method: 'get',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        const data = await response.json();
        data.forEach(item=>{
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async save(token, appointment){
        const response = await fetch(`${baseURL}/api/text`, {
            method: 'post', 
            body: JSON.stringify(appointment),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
        return await response.json();
    }

    async delete(token, text){
        const response = await fetch(`${baseURL}/api/text`, {
            method: 'delete',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return await response.json();
    }
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => obj[key] !== undefined ? key + '=' + obj[key] : undefined).filter(el => el!==undefined).join('&');
}