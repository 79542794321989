async function getConfig(){
    /*try {
        const response = await fetch(`/api/config.json`);
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const json = await response.json();
        return json;
    } catch (error) {
        console.log(error);
    }
    return null;*/
    return Promise.resolve({
        "enableVideos": true
    });
}


export {getConfig};