import React, {useState, useEffect, useRef} from 'react';

import mascot from './resources/feather.png';
import logo from './resources/logo.png';
import sponsors from './resources/sponsors.png';
import mp4 from './resources/cineoriginario.mp4';
import ogg from './resources/cineoriginario.ogv';
import {TiTicket} from 'react-icons/ti';
import {useSpring, animated} from 'react-spring';
import { withRouter } from 'react-router-dom';

function Home(props){
    const { history } = props;
    const [started, setStarted] = useState(false);
    const [hideVideo, setHideVideo] = useState(true);
    const [fadeOutOpacity, setFadeOutOpacity] = useState(1);
    const [fadeInOpacity, setFadeInOpacity] = useState(0);
    const fadeOut = useSpring({opacity: fadeOutOpacity, from: {opacity: 1}, config: {duration:1000}, onStart:()=>{
        setStarted(true);
    }});
    const fadeIn = useSpring({opacity: fadeInOpacity, from: {opacity: 0}, config: {duration:1000}, onRest: ()=>{
        if (started){
            setHideVideo(true);
        }
    }});
    const slideIn = useSpring({
        transform: 'translate3d(0,0,0)', 
        from: {transform: 'translate3d(0,-100%,0)'}, 
        config: {duration: 1500},
    });
    useEffect(()=>{
        const myVideoPlayer = document.getElementById('myVideoPlayer');
        if (myVideoPlayer && !hideVideo){
            myVideoPlayer.addEventListener('ended', e => {
                setFadeOutOpacity(0);
                setFadeInOpacity(1);
            }, false);
        }else{
            setFadeOutOpacity(0);
            setFadeInOpacity(1);
        }
    }, []);
    return (
        <>
            {!hideVideo && 
                <animated.div style={{...fadeOut}} id="myVideo">
                    <video preload="auto" autoPlay muted id="myVideoPlayer">
                        <source src={mp4} type="video/mp4" />
                        <source src={ogg} type="video/ogg" />
                    </video>
                </animated.div>
            }
            <div className="App-header">
                <div className="App-mascot">
                    <animated.div style={{...slideIn, ...fadeIn}}/>
                    <animated.img style={fadeIn} src={mascot} className="mascot" alt="logo" />
                </div>
                <animated.img src={logo} className="App-logo" alt="logo" style={fadeIn} />
                {/*<animated.p style={{...fadeIn, zIndex: "4", backgroundColor: "#1a1617"}}>
                    <b>11</b> al <b>15</b> de marzo 2021
                </animated.p>*/}
                {/*<animated.button 
                    style={{padding: "3vmin", fontSize: "calc(10px + 1.5vmin)", backgroundColor: "#fecc0d", border: "none", borderRadius: "20px"}} 
                    onClick={()=>{
                        history.push("/list");
                        //window.location.assign('/movie/604a9f684e2c2c0013eca507')
                    }}
                >
                    Ingresar <TiTicket />
                </animated.button>
                <animated.img src={sponsors} className="App-logo" alt="logo" style={fadeIn} />
                {<button style={{padding: "3vmin", fontSize: "calc(10px + 1.5vmin)", backgroundColor: "#fecc0d", border: "none", borderRadius: "20px"}} onClick={()=>history.push("/list")}>
                    Ingresar <TiTicket />
                </button>*/}
            </div>
        </>
    );
}

export default withRouter(Home);