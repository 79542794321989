const baseURL = `${(window.location.hostname === "localhost" ? "http://localhost":`${window.location.protocol}//${window.location.hostname}`)}`;

export default class OrderUseCases {
    async getAll(since=undefined, until=undefined, active=1, paymentComplete=undefined, id_client=undefined){
        const params = {since, until, active, paymentComplete, id_client};
        const response = await fetch(`${baseURL}/api/order/list?${objectToQueryString(params)}`, {method: 'get'});
        const data = await response.json();
        const now = new Date();
        data.forEach(item=>{
            const swtValues = item.startWorkingTime.split(":");
            item.startWorkingTime = new Date(
                now.getFullYear(), now.getMonth(), now.getDate(), 
                parseInt(swtValues[0], 10), parseInt(swtValues[1],10), parseInt(swtValues[2],10)
            );
            const ewtValues = item.endWorkingTime.split(":");
            item.endWorkingTime = new Date(
                now.getFullYear(), now.getMonth(), now.getDate(), 
                parseInt(ewtValues[0], 10), parseInt(ewtValues[1],10), parseInt(ewtValues[2],10)
            );
            item.created_at = new Date(item.created_at);
        });
        return data;
    }

    async save(worker, idUser){
        const response = await fetch(`${baseURL}/api/order/save/${idUser}`, {
            method: 'post', 
            body: JSON.stringify(worker),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return await response.json();
    }

    async delete(order){
        const response = await fetch(`${baseURL}/api/order/delete/${order.id}`, {method: 'delete'});
        return await response.json();
    }
}

function objectToQueryString(obj) {
    return Object.keys(obj).map(key => obj[key] !== undefined ? key + '=' + obj[key] : undefined).filter(el => el!==undefined).join('&');
  }